<app-list-payment-methods [paymentMethod]="paymentMethod"></app-list-payment-methods>
<div *ngIf="(paymentMethods$ | async)!.length < 3; else maxAccountsReached">
  <app-add-payment-method
    [paymentMethod]="paymentMethod"
    [returnUrl]="returnUrl"
  ></app-add-payment-method>
</div>
<ng-template #maxAccountsReached>
  <div style="margin: 24px 0">
    <p
      >You have reached the max number of accounts. You may remove an account in order to connect a
      new one.</p
    >
  </div>
</ng-template>
