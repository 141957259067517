import { Injectable } from '@angular/core';

import { v4 as uuid } from 'uuid';
import { HttpHeaders } from '@angular/common/http';
import { ConfigurationService } from '../configuration/configuration.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(private configService: ConfigurationService) {}

  // Creates and returns HttpHeaders, using default values if not present
  httpHeaders(
    FID_LOG_TRACKING_ID: string = uuid(),
    CONTENT_TYPE = 'application/json',
  ): HttpHeaders {
    const headers = new HttpHeaders()
      .set('Content-Type', CONTENT_TYPE)
      .set('FID-LOG-TRACKING-ID', FID_LOG_TRACKING_ID)
      .set('FID-ORIGINATING-APP-ID', this.configService.getAppId());
    return headers;
  }
}
