import { Component, Input } from '@angular/core';
import { Appearance, Stripe, StripeElements, loadStripe } from '@stripe/stripe-js';
import { PaymentsService, ConfigurationService, PaymentEventService } from '../../services';
import { HttpErrorResponse } from '@angular/common/http';
import { PaymentType } from '../../../types/payment-options';
import { AlertType } from '../../../types/alert-type';

@Component({
  selector: 'app-add-payment-method',
  templateUrl: './add-payment-method.component.html',
  styleUrls: ['./add-payment-method.component.scss'],
})

// Component for handling adding a payment method for us-ach
export class AddPaymentMethodComponent {
  @Input() paymentMethod: PaymentType;
  @Input() returnUrl: string;

  showAddPaymentMethodModal = false;

  setupIntentLoading = false;

  alertType = AlertType.CRITICAL;
  setupIntentError = false;
  setupIntentErrorResponse = new HttpErrorResponse({});

  stripePaymentElementLoadError = false;
  stripePaymentElementLoadErrorMessage = '';

  stripePaymentElementSubmitError = false;
  stripePaymentElementSubmitErrorMessage = '';

  didMountElements = false;

  stripe: Stripe | null;
  elements: StripeElements;

  isSubmitting = false;

  constructor(
    private paymentsService: PaymentsService,
    private configService: ConfigurationService,
    private paymentEventService: PaymentEventService,
  ) {}

  toggleAddPaymentMethodModal() {
    this.showAddPaymentMethodModal = true;
    // Only attempt to mount the StripeJS element if it has not been mounted before
    if (!this.didMountElements) {
      this.setupIntentLoading = true;
      // Create SetupIntent for this customer
      this.paymentsService
        .createSetupIntent(this.paymentMethod)
        .then(async ({ client_secret }) => {
          // Load stripe using publishable key
          this.stripe = await this.initializeStripe();
          if (this.stripe) {
            // initialize stripe JS with pre-loaded data and setup intent id
            const appearance: Appearance = {
              theme: 'stripe',
              variables: {
                colorPrimary: '#8cad50',
                colorBackground: '#ffffff',
                colorText: '#202020',
                colorDanger: '#e01616',
                fontFamily: 'Fidelity Sans, system-ui, sans-serif',
                spacingUnit: '6px',
                borderRadius: '4px',
              },
              rules: {
                '.Input': {
                  lineHeight: 'normal',
                  padding: '1em .75em 1em .75em',
                },
                '.Input:focus': {
                  borderRadius: '5px',
                  borderWidth: '2px',
                  boxShadow: 'none',
                  borderColor: '#8cad50',
                },
              },
            };
            this.elements = this.stripe.elements({
              clientSecret: client_secret,
              appearance: appearance,
            });

            const userBillingDetails = this.paymentEventService.getUserBillingDetails();

            const paymentElement = this.elements.create('payment', {
              defaultValues: {
                billingDetails: {
                  name: userBillingDetails.name,
                  email: userBillingDetails.email,
                },
              },
            });
            // Mount Stripe element
            paymentElement.mount('#payment-element');
            // Listen for StripeJS load errors across the load cycle
            paymentElement.on('loaderror', () => {
              this.stripePaymentElementLoadError = true;
              this.stripePaymentElementLoadErrorMessage =
                'An error has occurred loading Stripe, please try again later!';
            });
          } else {
            // Show error if the stripe element did not load
            this.stripePaymentElementLoadError = true;
            this.stripePaymentElementLoadErrorMessage =
              'An error has occurred loading Stripe, please try again later!';
          }
          this.setupIntentLoading = false;
          this.didMountElements = true;
        })
        .catch((error) => {
          // Catch any API errors from setup intent call
          this.setupIntentLoading = false;
          this.setupIntentError = true;
          this.setupIntentErrorResponse = error;
        });
    }
  }

  hideAddPaymentMethodModal() {
    // close modal and reset errors
    this.showAddPaymentMethodModal = false;
    this.setupIntentError = false;
    this.setupIntentErrorResponse = new HttpErrorResponse({});
    this.stripePaymentElementLoadError = false;
    this.stripePaymentElementLoadErrorMessage = '';
    this.stripePaymentElementSubmitError = false;
    this.stripePaymentElementSubmitErrorMessage = '';
  }

  async onSubmit(): Promise<void> {
    if (!this.stripe || !this.elements) {
      // Do not allow form to be submitted if stripe or elements hasn't been mounted
      return;
    }
    this.isSubmitting = true;
    // Call StripeJS confirm setup with return_url (defaulted to current base path for now)
    const result = await this.stripe.confirmSetup({
      elements: this.elements,
      confirmParams: {
        return_url: this.returnUrl,
      },
    });

    // Display errors if submission did not work
    if (result.error) {
      this.isSubmitting = false;
      this.stripePaymentElementSubmitError = true;
      this.stripePaymentElementSubmitErrorMessage =
        'A problem has occurred when trying to submit your payment details. Please verify below and try again.';
    }
  }

  async initializeStripe(): Promise<Stripe | null> {
    return await loadStripe(this.configService.getStripePublishableKey());
  }
}
