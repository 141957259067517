import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent implements OnChanges {
  @Input() showSpinner: boolean;
  @Input() spinnerMessageOverride: string;
  spinnerMessage: string = 'Loading...';

  ngOnChanges(): void {
    if (this.spinnerMessageOverride) {
      this.spinnerMessage = this.spinnerMessageOverride;
    }
  }
}
