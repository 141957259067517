import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, firstValueFrom, throwError } from 'rxjs';
import { endpoints } from '../endpoints';
import { AuthenticationService } from '../authentication/authentication.service';
import { ConfigurationService } from '../configuration/configuration.service';
import { PaymentType } from '../../../types/payment-options';
import { mockResponses } from '../mockObjects';
import { EnvironmentType } from '../../../types/environment-type';

@Injectable({
  providedIn: 'root',
})
export class AccountsService {
  private readonly baseUrl: string;
  private readonly env: string;

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private configService: ConfigurationService,
  ) {
    this.baseUrl = this.configService.getVAMMBaseUrl();
    this.env = this.configService.getEnv();
  }

  // Create a new participant for a given payment type
  async createParticipant(paymentType: PaymentType): Promise<any> {
    if (this.env === EnvironmentType.MOCK) {
      return mockResponses.ACCOUNTS.createParticipant;
    }
    return await firstValueFrom(
      this.http
        .put(
          `${this.baseUrl}${endpoints.ACCOUNTS.CREATE_PARTICIPANT(paymentType)}`,
          {},
          {
            headers: this.authenticationService.httpHeaders(),
            withCredentials: true,
          },
        )
        .pipe(
          catchError((error: HttpErrorResponse) => {
            console.error('Error in CreateParticipant:', error.message);
            return throwError(() => error);
          }),
        ),
    );
  }
}
