<div style="margin: 24px 0">
  <button
    class="pvd-button-root pvd-button--tertiary"
    type="button"
    (click)="toggleAddPaymentMethodModal()"
    style="margin-right: 8px"
    id="link-bank-account-button"
  >
    <div class="pvd-button__contents">+ Link a Bank Account</div>
  </button>
</div>
<app-modal
  [showModal]="showAddPaymentMethodModal"
  [width]="800"
  (emitClose)="hideAddPaymentMethodModal()"
  id="add-payment-method-modal"
>
  <div class="pvd-modal__heading-wrapper" id="add-payment-method-modal-heading">
    <h3 slot="heading" class="pvd-modal__heading">Link a Bank Account</h3>
  </div>
  <div class="pvd-modal__body-wrapper" id="add-payment-method-modal-body-wrapper">
    <app-alert
      [showAlert]="stripePaymentElementSubmitError"
      [alertMessageOverride]="stripePaymentElementSubmitErrorMessage"
      [type]="alertType"
    ></app-alert>
    <p style="margin-bottom: 24px"
      >Please select a financial connection in order to link a bank account</p
    >
    <app-spinner [showSpinner]="setupIntentLoading || isSubmitting"></app-spinner>
    <app-alert
      [showAlert]="setupIntentError"
      [errorResponse]="setupIntentErrorResponse"
      [type]="alertType"
    ></app-alert>
    <div id="payment-element" style="margin-top: 16px" [hidden]="isSubmitting">
      <!-- a Stripe Element will be inserted here when mounted. -->
    </div>
  </div>
  <div
    style="
      margin-top: 8px;
      padding: 16px;
      border-top: #d3d3d3 1px solid;
      display: flex;
      justify-content: center;
    "
  >
    <button class="pvd-button-root pvd-button--tertiary" type="button" style="margin-right: 8px">
      <div
        class="pvd-button__contents"
        (click)="hideAddPaymentMethodModal()"
        id="add-payment-method-modal-cancel-button"
        >Cancel</div
      >
    </button>
    <button
      [disabled]="!this.stripe || !this.elements || isSubmitting"
      class="pvd-button-root pvd-button--with-icon pvd-button--primary"
      type="button"
      (click)="onSubmit()"
      id="add-payment-method-modal-submit-button"
    >
      <div class="pvd-button__contents">
        <span class="pvd-button__text">Continue</span>
      </div>
    </button>
  </div>
</app-modal>
