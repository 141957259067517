import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SendPaymentComponent } from './send-payment.component';
import { ComponentsModule } from '../components/components.module';
import { PaymentMethodModule } from '../payment-method/payment-method.module';
import { HttpClientModule } from '@angular/common/http';
import {
  AccountsService,
  PaymentEventService,
  PaymentsService,
  UserConsentService,
} from '../services';

@NgModule({
  declarations: [SendPaymentComponent],
  imports: [CommonModule, ComponentsModule, HttpClientModule, PaymentMethodModule],
  providers: [UserConsentService, PaymentEventService, PaymentsService, AccountsService],
  exports: [SendPaymentComponent],
})
export class SendPaymentModule {}
