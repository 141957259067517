<app-modal
  [showModal]="displayPaymentMethodRemovalModal"
  [width]="600"
  (emitClose)="closeRemovalModal()"
  id="remove-payment-method-modal"
>
  <div class="pvd-modal__heading-wrapper" id="remove-modal-heading">
    <h3 slot="heading" class="pvd-modal__heading">Are you sure you want to remove this account?</h3>
  </div>
  <div class="pvd-modal__body-wrapper" id="remove-modal-body-wrapper">
    <p style="margin-bottom: 24px" *ngIf="!removalInProgress"
      >Once the connected account is removed, the information will be deleted. If you want to add
      the account back you will need to enter the information.
    </p>
    <app-spinner
      [showSpinner]="removalInProgress"
      [spinnerMessageOverride]="removePaymentSpinnerMsgOverride"
    ></app-spinner>
  </div>
  <div
    style="
      margin-top: 8px;
      padding: 16px;
      border-top: #d3d3d3 1px solid;
      display: flex;
      justify-content: center;
    "
  >
    <button
      class="pvd-button-root pvd-button--tertiary"
      type="button"
      style="margin-right: 8px"
      id="remove-payment-method-cancel-button"
      (click)="closeRemovalModal()"
      [disabled]="removalInProgress"
    >
      <div class="pvd-button__contents">Cancel</div>
    </button>
    <button
      class="pvd-button-root pvd-button--with-icon pvd-button--primary"
      type="button"
      id="remove-payment-method-button"
      (click)="removePaymentMethod()"
      [disabled]="removalInProgress"
    >
      <div class="pvd-button__contents">
        <span class="pvd-button__text">Remove</span>
      </div>
    </button>
  </div>
  <app-alert
    [showAlert]="removePaymentMethodError"
    [errorResponse]="removePaymentMethodErrorResponse"
    [type]="removePaymentAlertType"
  ></app-alert>
</app-modal>

<app-spinner [showSpinner]="paymentMethodsLoading"></app-spinner>
<app-alert
  [showAlert]="listPaymentMethodError"
  [errorResponse]="listPaymentMethodErrorResponse"
  [type]="listPaymentAlertType"
></app-alert>
<div *ngIf="!paymentMethodsLoading && !listPaymentMethodError">
  <div class="pvd-field-group-root pvd-field-group--full-width pvd-field-group--vertical-align-top">
    <div class="pvd-field-group__field-group">
      <div class="pvd-label-root pvd-label--medium pvd-label--primary">
        <div class="pvd-label__label">
          <h4> Select bank account to use for payment </h4>
        </div>
      </div>
      <div
        class="pvd-radio-group-root pvd-radio-group--tiles"
        *ngFor="let pm of availablePaymentMethods$ | async"
      >
        <div
          class="pvd-radio-group__radio-group"
          role="radiogroup"
          tabindex="-1"
          aria-required="true"
          style="margin: 24px 0"
          id="{{ pm.id }}"
        >
          <div class="pvd-radio-root pvd-radio--medium">
            <input
              class="pvd-radio__radio"
              type="radio"
              id="{{ pm.id }}-radio-button"
              name="payment-method-group"
              value="{{ pm.id }}"
              (change)="propagateRadioSelection(pm.id)"
            />
            <label
              class="pvd-radio__label"
              style="margin: 0; height: auto; display: flex"
              for="{{ pm.id }}-radio-button"
            >
              <div aria-hidden="true" class="pvd-radio__radio-wrap"></div>
              <div class="pvd-radio__label-text" style="flex-grow: 1"
                >{{ pm.us_bank_account.bank_name | titlecase }}
                <div class="pvd-microcopy-root">
                  <p class="pvd-microcopy__microcopy">•••••• {{ pm.us_bank_account.last4 }}</p>
                </div>
              </div>
              <span class="pvd-link-root pvd-link--icon-only" style="margin-top: 9px">
                <button
                  type="button"
                  class="pvd-link__link"
                  id="{{ pm.id }}-remove-payment-method-modal-button"
                  (click)="displayRemovalModal(pm.id)"
                >
                  <span class="pvd-link__icon pvd-link__icon--left">
                    <svg aria-hidden="true" class="pvd-icon-root pvd-icon--small" focusable="false">
                      <use href="#pvd-action__delete" xlink:href="#pvd-action__delete"></use>
                    </svg>
                  </span>
                </button>
              </span>
            </label>
          </div>
        </div>
      </div>
      <div
        *ngIf="(availablePaymentMethods$ | async)!.length === 0"
        style="
          display: flex;
          padding: 8px 16px 16px 16px;
          align-items: flex-start;
          flex-shrink: 0;
          border: thin solid #cccccc;
          border-radius: 8px;
          margin: 24px 0;
        "
      >
        <p
          >You don’t have any linked bank accounts right now. To pay for options, you’ll need to
          link an account.</p
        >
      </div>
    </div>
  </div>
</div>
