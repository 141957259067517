import { Component, EventEmitter, Input, Output } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [style({ opacity: 0 }), animate('0.2s ease-in', style({ opacity: 1 }))]),
    ]),
  ],
})
export class ModalComponent {
  @Input() showModal: boolean;
  @Input() id: string;
  @Input() width: number;
  @Output() emitClose = new EventEmitter<void>();

  close() {
    this.emitClose.emit();
  }
}
