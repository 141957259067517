import { Currency, PaymentOptions, PaymentType } from '../../types/payment-options';
import { EnvironmentType } from '../../types/environment-type';
import { BASE_URL } from './configuration/constants';

export const mockResponses = {
  ACCOUNTS: {
    createParticipant: {
      id: 'cus_9s6XKzkNRiz8i3',
      object: 'customer',
      address: null,
      balance: 0,
      created: 1483565364,
      currency: 'usd',
      default_source: 'card_1NZex82eZvKYlo2CZR21ocY1',
      delinquent: false,
      description: 'Casetabs Organization',
      discount: null,
      email: 'test@test.com',
      invoice_prefix: '28278FC',
      invoice_settings: {
        custom_fields: null,
        default_payment_method: null,
        footer: null,
        rendering_options: null,
      },
      livemode: false,
      metadata: {
        order_id: '6735',
      },
      name: 'Test Participant',
      next_invoice_sequence: 193,
      phone: null,
      preferred_locales: [],
      shipping: null,
      tax_exempt: 'none',
      test_clock: null,
    },
  },
  PAYMENTS: {
    createSetupIntent: {
      id: 'seti_1O6xP22eZvKYlo2Clbo4n569',
      object: 'setup_intent',
      application: null,
      automatic_payment_methods: {
        enabled: true,
      },
      cancellation_reason: null,
      client_secret: 'seti_1O6xP22eZvKYlo2Clbo4n569_secret_OumzmukwIljEyWgQmIB1FKo8ljupjKc',
      created: 1698679760,
      customer: null,
      description: null,
      flow_directions: null,
      last_setup_error: null,
      latest_attempt: null,
      livemode: false,
      mandate: null,
      metadata: {},
      next_action: null,
      on_behalf_of: null,
      payment_method: null,
      payment_method_configuration_details: null,
      payment_method_options: {
        card: {
          mandate_options: null,
          network: null,
          request_three_d_secure: 'automatic',
        },
      },
      payment_method_types: ['card'],
      redaction: null,
      single_use_mandate: null,
      status: 'requires_payment_method',
      usage: 'off_session',
    },
    createPaymentIntent: {
      transactionId: 'abcd123',
      status: 'pending',
      amount: 500,
    },
    getPaymentMethods: [
      {
        billing_details: {
          address: {},
          email: 'jane.doe@fmr.com',
          name: 'Jane Doe',
        },
        created: 1707418663,
        customer: 'cus_P2ftQln8e5fnDX',
        id: 'pm_1Ohcn4LmfdKxAOg99YFRkwrZ',
        livemode: false,
        metadata: {},
        object: 'payment_method',
        type: 'us_bank_account',
        us_bank_account: {
          account_holder_type: 'individual',
          account_type: 'checking',
          bank_name: 'STRIPE TEST BANK',
          financialConnectionsAccount: 'fca_1OOmseLmfdKxAOg9UxYzVwiE',
          fingerprint: 'VHDq27zaSfbXnyfb',
          last4: '6789',
          networks: {
            preferred: 'ach',
            supported: ['ach'],
          },
          routingNumber: '110000000',
          statusDetails: {},
        },
      },
      {
        billing_details: {
          address: {},
          email: 'jane.doe@fmr.com',
          name: 'Jane Doe',
        },
        created: 1707418632,
        customer: 'cus_P2ftQln8e5fnDX',
        id: 'pm_1OhcmaLmfdKxAOg92bfty6x0',
        livemode: false,
        metadata: {},
        object: 'payment_method',
        type: 'us_bank_account',
        us_bank_account: {
          account_holder_type: 'individual',
          account_type: 'checking',
          bank_name: 'STRIPE TEST BANK',
          financialConnectionsAccount: 'fca_1OOmseLmfdKxAOg9UxYzVwiE',
          fingerprint: 'VHDq27zaSfbXnyfb',
          last4: '6789',
          networks: {
            preferred: 'ach',
            supported: ['ach'],
          },
          routingNumber: '110000000',
          statusDetails: {},
        },
      },
      {
        billing_details: {
          address: {},
          email: 'jane.doe@fmr.com',
          name: 'Jane Doe',
        },
        created: 1707408961,
        customer: 'cus_P2ftQln8e5fnDX',
        id: 'pm_1OhaGaLmfdKxAOg9uNVYpA3m',
        livemode: false,
        metadata: {},
        object: 'payment_method',
        type: 'us_bank_account',
        us_bank_account: {
          account_holder_type: 'individual',
          account_type: 'checking',
          bank_name: 'STRIPE TEST BANK',
          financialConnectionsAccount: 'fca_1OOmseLmfdKxAOg9UxYzVwiE',
          fingerprint: 'VHDq27zaSfbXnyfb',
          last4: '6789',
          networks: {
            preferred: 'ach',
            supported: ['ach'],
          },
          routingNumber: '110000000',
          statusDetails: {},
        },
      },
    ],
  },
};

export const mockConfig = {
  LOCAL: {
    baseUrl: 'http://localhost:8080/sps-money-movement-integration-hub/',
    env: 'LOCAL',
  },
  DEV: {
    baseUrl: BASE_URL.DEV,
    env: EnvironmentType.DEV,
  },
  QA: {
    baseUrl: BASE_URL.QA,
    env: EnvironmentType.QA,
  },
  PROD: {
    baseUrl: BASE_URL.PROD,
    env: EnvironmentType.PROD,
  },
  MOCK: {
    baseUrl: BASE_URL.MOCK,
    env: EnvironmentType.MOCK,
  },
  WD_DEV: {
    baseUrl: BASE_URL.WD_DEV,
    env: EnvironmentType.WD_DEV,
  },
  WD_QA: {
    baseUrl: BASE_URL.WD_QA,
    env: EnvironmentType.WD_QA,
  },
  WD_PROD: {
    baseUrl: BASE_URL.WD_PROD,
    env: EnvironmentType.WD_PROD,
  },
};

export const mockPaymentOptions: PaymentOptions<PaymentType.US_ACH> = {
  paymentType: PaymentType.US_ACH,
  currency: Currency.USD,
  amount: 2500,
};
