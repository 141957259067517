import { Injectable } from '@angular/core';
import { EnvironmentType } from '../../../types/environment-type';
import * as props from './constants';

// Allows clients to set and retrieve environment specific fields
@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  private mm_base_url: string;
  private env: EnvironmentType;
  private appId: string;
  private stripePublishableKey: string;

  constructor() {
    this.mm_base_url = props.BASE_URL.WD_QA;
    this.stripePublishableKey = props.STRIPE_PUBLISHABLE_KEY.DEV;
  }

  setEnv(env: EnvironmentType): void {
    this.env = env;
    this.setProps(env);
  }

  setAppId(appId: string): void {
    this.appId = appId;
  }

  private setProps(env: EnvironmentType): void {
    switch (env) {
      case EnvironmentType.MOCK:
        this.mm_base_url = props.BASE_URL.MOCK;
        this.stripePublishableKey = props.STRIPE_PUBLISHABLE_KEY.DEV;
        break;
      case EnvironmentType.DEV:
        this.mm_base_url = props.BASE_URL.DEV;
        this.stripePublishableKey = props.STRIPE_PUBLISHABLE_KEY.DEV;
        break;
      case EnvironmentType.QA:
        this.mm_base_url = props.BASE_URL.QA;
        this.stripePublishableKey = props.STRIPE_PUBLISHABLE_KEY.DEV;
        break;
      case EnvironmentType.PROD:
        this.mm_base_url = props.BASE_URL.PROD;
        this.stripePublishableKey = props.STRIPE_PUBLISHABLE_KEY.PROD;
        break;
      case EnvironmentType.WD_DEV:
        this.mm_base_url = props.BASE_URL.WD_DEV;
        this.stripePublishableKey = props.STRIPE_PUBLISHABLE_KEY.DEV;
        break;
      case EnvironmentType.WD_QA:
        this.mm_base_url = props.BASE_URL.WD_QA;
        this.stripePublishableKey = props.STRIPE_PUBLISHABLE_KEY.DEV;
        break;
      case EnvironmentType.WD_PROD:
        this.mm_base_url = props.BASE_URL.WD_PROD;
        this.stripePublishableKey = props.STRIPE_PUBLISHABLE_KEY.PROD;
        break;
      default:
        this.mm_base_url = props.BASE_URL.WD_QA;
        this.stripePublishableKey = props.STRIPE_PUBLISHABLE_KEY.DEV;
        break;
    }
  }

  getEnv(): EnvironmentType {
    return this.env;
  }
  getStripePublishableKey(): string {
    return this.stripePublishableKey;
  }
  getVAMMBaseUrl(): string {
    return this.mm_base_url;
  }
  getAppId(): string {
    return this.appId;
  }
}
