import { PaymentType } from '../../types/payment-options';

const version = 'v1';
export const endpoints = {
  ACCOUNTS: {
    CREATE_PARTICIPANT: (paymentType: PaymentType): string => {
      return `${version}/accounts/payment-instructions/participants/${paymentType}/create`;
    },
  },
  PAYMENTS: {
    CREATE_SETUP_INTENT: (paymentType: PaymentType): string => {
      return `${version}/accounts/payments/participants/${paymentType}/setup`;
    },
    CREATE_PAYMENT_INTENT: (clientId: string, paymentType: PaymentType): string => {
      return `${version}/accounts/payments/plan-sponsors/${clientId}/${paymentType}/request`;
    },
    GET_PAYMENT_METHODS: (paymentType: PaymentType): string => {
      return `${version}/accounts/payments/participants/${paymentType}/saved`;
    },
    DELETE_PAYMENT_METHOD: (paymentType: PaymentType, paymentMethodId: string): string => {
      return `${version}/accounts/payments/participants/${paymentType}/saved/${paymentMethodId}`;
    },
  },
};
