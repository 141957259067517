<div *ngIf="showAlert">
  <div class="pvd-alert-root pvd-alert--{{ type }}">
    <div class="pvd-alert__icon">
      <svg aria-hidden="true" class="pvd-icon-root pvd-icon--large" focusable="false">
        <use href="#pvd-alert__critical--outline" xlink:href="#pvd-alert__critical--outline"></use>
        <!--somehow need to inject type here-->
      </svg>
    </div>
    <div class="pvd-alert__content">
      <span class="pvd-alert__content-a11y-text">critical message</span>
      <p class="pvd-alert__message" id="alert-message">{{ alertMessage }}</p>
    </div>
  </div>
</div>
