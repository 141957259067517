import { NgModule } from '@angular/core';
import { ComponentsModule } from '../components/components.module';
import { CommonModule } from '@angular/common';
import { AddPaymentMethodComponent } from './add-payment-method/add-payment-method.component';
import { ListPaymentMethodsComponent } from './list-payment-methods/list-payment-methods.component';
import { PaymentMethodComponent } from './payment-method.component';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [AddPaymentMethodComponent, ListPaymentMethodsComponent, PaymentMethodComponent],
  imports: [CommonModule, ComponentsModule, HttpClientModule],
  providers: [], //providers inherited from global injector per "providedIn: root"
  exports: [PaymentMethodComponent],
})
export class PaymentMethodModule {}
