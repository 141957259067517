import { Injectable } from '@angular/core';
import { ConsentStatus } from '../../../types/consent-status';

@Injectable({
  providedIn: 'root',
})
export class UserConsentService {
  private didUserConsent = ConsentStatus.PENDING;

  acknowledgeConsent(): void {
    this.didUserConsent = ConsentStatus.ACCEPTED;
  }

  declineConsent(): void {
    this.didUserConsent = ConsentStatus.DECLINED;
  }

  getConsentStatus(): ConsentStatus {
    return this.didUserConsent;
  }
}
