export enum STRIPE_PUBLISHABLE_KEY {
  DEV = 'pk_test_51Oj1zkCECYcKMTZ2O8njv0DFa7ePdo3cbADaCf4e4ep202DZ7XfwdPjPyd1W7HV4H7QFnpaMsMiO47SbugropIat00kcpe2i4e',
  PROD = 'pk_live_51Oj1zkCECYcKMTZ2lwQdxh6q72MqvZFZMmjOPKNjf7dcvwl5PeQVLHhUKC1wi0vaSuZQ03vOY96wqUKYXJVo1IVI00806kpvJq',
}

//DEV,QA,PROD are proxy mappings to Workplace Digital (proxies stored in consuming applications).  WD_DEV,QA,PROD are direct URI paths.
export enum BASE_URL {
  DEV = '/moneymovementapi-dev/', //set to localhost for local server development
  QA = '/moneymovementapi-qa/',
  PROD = '/moneymovementapi-prod/',
  MOCK = '/moneymovementapi-mock/',
  WD_DEV = 'https://workplacedigital-dev.fidelity.com/sps-money-movement-integration-hub/',
  WD_QA = 'https://workplacedigital-xqa.fidelity.com/sps-money-movement-integration-hub/',
  WD_PROD = 'https://workplacedigital.fidelity.com/sps-money-movement-integration-hub/',
}
