import { Component, Input, OnChanges } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { AlertType } from '../../../types/alert-type';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnChanges {
  @Input() showAlert: boolean;
  @Input() errorResponse: HttpErrorResponse;
  @Input() alertMessageOverride: string;
  @Input() type: AlertType;
  alertMessage: string;

  ngOnChanges() {
    if (this.alertMessageOverride?.length > 0) {
      // Use the frontend message if we're overriding
      this.alertMessage = this.alertMessageOverride;
    } else if (this.errorResponse?.error?.errors) {
      // pull the alert message from the backend errors
      this.alertMessage = this.errorResponse.error?.errors[0]?.detail;
    } else {
      // default message
      this.alertMessage = 'An unexpected error occurred; please try again later';
    }
  }
}
