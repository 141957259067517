<div *ngIf="showSpinner">
  <div
    class="pvd-spinner-root pvd-spinner--neutral pvd-spinner--medium"
    role="status"
    tabindex="-1"
  >
    <div class="pvd-spinner__spinner" aria-hidden="true">
      <div class="pvd-spinner__visual">
        <div class="pvd-spinner__mask pvd-spinner__mask--left">
          <div class="pvd-spinner__mask-inner"></div>
        </div>
        <div class="pvd-spinner__mask pvd-spinner__mask--right">
          <div class="pvd-spinner__mask-inner"></div>
        </div>
      </div>
    </div>
    <div class="pvd-spinner__microcopy">
      <div class="pvd-spinner__microcopy-primary">
        <p class="pvd-spinner__microcopy-text" id="spinner-message">{{ spinnerMessage }}</p>
      </div>
      <p class="pvd-spinner__microcopy-secondary">This could take a moment.</p>
    </div>
  </div>
</div>
