export enum PaymentType {
  US_ACH = 'us-ach',
}

export enum Currency {
  USD = 'usd',
}

type PaymentTypeCurrencyMap = {
  [PaymentType.US_ACH]: Currency.USD;
};

export type PaymentOptions<T extends PaymentType> = {
  paymentType: T;
  currency: PaymentTypeCurrencyMap[T];
  amount: number;
};
